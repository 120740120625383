import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-garland-texas.png'
import image0 from "../../images/cities/scale-model-of-magic-fun-house-castle-in-garland-texas.png"
import image1 from "../../images/cities/scale-model-of-rory-meyers-children's-adventure-garden-at-the-dallas-arboretum-in-garland-texas.png"
import image2 from "../../images/cities/scale-model-of-garland-in-garland-texas.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Garland'
            state='Texas'
            image={image}
            lat='32.9098'
            lon='-96.6303'
            attractions={ [{"name": "Magic Fun House Castle", "vicinity": "4100 Rowlett Rd, Rowlett", "types": ["art_gallery", "home_goods_store", "store", "point_of_interest", "establishment"], "lat": 32.8984744, "lng": -96.569163}, {"name": "Rory Meyers Children's Adventure Garden at the Dallas Arboretum", "vicinity": "8525 Garland Rd, Dallas", "types": ["amusement_park", "point_of_interest", "establishment"], "lat": 32.8254028, "lng": -96.7136501}, {"name": "Garland", "vicinity": "200 N 5th St, Garland", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 32.9137262, "lng": -96.63608970000001}] }
            attractionImages={ {"Magic Fun House Castle":image0,"Rory Meyers Children's Adventure Garden at the Dallas Arboretum":image1,"Garland":image2,} }
       />);
  }
}